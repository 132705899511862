<script>
import LayoutLoose from "@/router/layouts/LayoutLoose";
import appConfig from "@/app.config";

import {mapState} from "vuex";
import {HalfCircleSpinner} from "epic-spinners";

export default {
  page: {
    title: "Teams",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    LayoutLoose,
    Skeleton: () => import('@/components/lazy/teams/view/Skeleton')
  },
  data() {
    return {
      team_id: this.$route.params.team_id
    }
  }
};
</script>

<template>
  <LayoutLoose>
    <Skeleton :team_id="team_id" :organization_id="this.$route.meta.organization_id" />
  </LayoutLoose>
</template>